@tailwind base;
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  ul, ol {
    @apply pl-3;
    @apply ml-3;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
@import '~react-toastify/dist/ReactToastify.min.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

input::-webkit-input-placeholder {
  color: #c3c3c3 !important;
}

textarea::-webkit-input-placeholder {
  color: #c3c3c3 !important;
}


/* table css */

.col-item {
    position: relative;
    border-bottom: 1px solid #e1e4e5;
    min-height: 70px;
    display: grid;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
}

.col-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    color: #5c6f7a;
}

.col-content[data-accessor='name'], .col-content[data-accessor='createAt']{
  font-weight: 600;
}

/* datepicker css */
.react-datetimerange-picker  {
  height: 48px;
}

.react-datetimerange-picker__wrapper {
  border-radius: 10px;
  border: 2px solid #e5e7ed !important;
  padding-left: 10px;
  padding-right: 10px;
  color: #6b7280;
}

.ellipsis-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
}

.ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.max-width-300 {
  max-width: 300px;
}